<template>
  <div class="auction-item" @click="handleGotoAuctionDetail">
    <div class="cover">
      <img class="cover-img" :src="img" alt="" />
      <DuTag
        v-if="item?.isJoin && item.type !== 'BLIND'"
        class="isLeader"
        size="small"
        :color="
          item?.isLeader
            ? { text: '#fff', background: '#379E45' }
            : { text: '#fff', background: '#D94A4E' }
        "
      >
        {{ item?.isLeader ? "领先中" : "被超越" }}
      </DuTag>
      <DuTag
        v-if="item?.spu"
        class="spu"
        style="width: calc(100% - 16px)"
        size="small"
        :color="{ text: '#fff', background: 'rgba(0, 0, 0, 0.64)' }"
      >
        <div class="otext">{{ item.spu?.name }}</div>
      </DuTag>
      <Countdown
        v-if="item.status === 1 || item.status === 6"
        :finishTime="item.finishTime"
        :startTime="item.startTime"
      />
    </div>
    <div class="title H5 otext2">
      <text>{{ item?.title || item?.description }}</text>
    </div>
    <div class="u-flex-c-sb">
      <div
        :class="{
          'label otext': true,
          'label-trade': !result && label !== '起拍价',
        }"
      >
        <div class="auctionPrice">
          <div class="N7">¥</div>
          <div class="N5">{{ auctionPrice }}</div>
        </div>
        <div
          v-if="item.status !== 6 && item.type === 'BLIND'"
          class="startingPrice"
        >
          <div class="B6">(</div>
          <div class="N6">¥{{ item.startingPrice }}</div>
          <div class="B8">起拍</div>
          <div class="B6">)</div>
        </div>
        <div class="B8">{{ label }}</div>
      </div>
      <DuTag v-if="item?.tagNameForLinjie" bg="solid" size="mini" class="otext">
        <div class="otext">
          {{ item.tagNameForLinjie }}
        </div>
      </DuTag>
    </div>

    <div class="seller" v-if="item?.seller">
      <img
        class="avatar"
        :src="item.seller.avatar + '!fill_w360_h480_jpg'"
        alt=""
      />
      <div class="nickname B8 otext">
        {{ item.seller.nickname }}
      </div>
      <DuIcon
        v-if="item.seller.isCertificationCompleted"
        size='3.2rem'
        color="#3BBA65"
        name="wepay"
      />
    </div>
    <image
      v-if="result === 'SUCCESS'"
      class="absolute right-0 bottom-0 w-48 h-48"
      src="https://cdn.qiandaoapp.com/interior/images/f8260d784e99a68ef2e057fb55126185.png"
    />
    <image
      v-else-if="result === 'FAIL'"
      class="absolute right-0 bottom-0 w-48 h-48"
      src="https://cdn.qiandaoapp.com/interior/images/f9a8d2ae62c1a78f66fb1589b74bea7a.png"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import Countdown from "./countdown.vue";
import { DuIcon, DuTag } from "dangoui";
import Utils from "./utils.js";
import { defineProps, defineEmits } from "vue";
import jb from "@frontend/effuse";

const router = useRouter();

import dayjs from "dayjs";
dayjs.locale("zh-cn");
const emit = defineEmits(["item-tap"]);
const props = defineProps({
  ubtName: {
    type: String,
    default: "",
  },
  isStartWatch: {
    type: Boolean,
    default: true,
  },
  ubtIndex: {
    type: Number,
  },
  isShowField: {
    type: Boolean,
  },
  ubtValue: {
    type: Object,
    default: null,
  },
  auction: {
    type: Object,
    default: null,
  },
});
const item = computed(() => {
  const { auction } = props;

  return Utils.updateAuction(auction);
});
const result = computed(() => {
  const { status } = item.value;

  if (status === 3 || status === 4) {
    return "SUCCESS";
  }

  if (status === 2 || status === 5) {
    return "FAIL";
  }
  if (status === 6) {
    return "WAITING";
  }
  return null;
});
const img = computed(() => {
  const { cover, medias } = item.value;
  if (cover) {
    return cover;
  }
  return medias?.find((e) => e.type === "image")?.url || "";
});

const label = computed(() => {
  const { currentPrice, type, status } = item.value;

  if (result.value === "SUCCESS") {
    return "成交价";
  } else {
    if (type === "BLIND") {
      return status === 6 ? "起拍价" : "当前价";
    } else {
      return currentPrice ? "当前价" : "起拍价";
    }
  }
});
const auctionPrice = computed(() => {
  const { startingPrice, currentPrice, hammerPrice, type, status } = item.value;
  if (result.value === "SUCCESS") {
    return hammerPrice;
  } else {
    if (type === "BLIND") {
      return status === 6 ? startingPrice : "???";
    } else {
      return startingPrice > currentPrice ? startingPrice : currentPrice;
    }
  }
});
function handleGotoAuctionDetail() {
  if (window.isInFlutter) {
    jb.navigateTo({ url: `congress://product?tradeId=${props.auction.id}` });
  } else {
    router.push(`/product-detail?tradeId=${props.auction.id}`);
  }
}
</script>

<style lang="scss">
.auction-item {
  display: flex;
  flex-direction: column;
  gap: 8rpx;
  padding-bottom: 8rpx;
  position: relative;
  .cover {
    border-radius: 16rpx;
    overflow: hidden;
    width: 100%;
    height: 343rpx;
    position: relative;
    .cover-img {
      width: 100%;
      height: 343rpx;
      object-fit: cover;
    }
    .isLeader {
      position: absolute;
      top: 16rpx;
      left: 16rpx;
    }
    .spu {
      position: absolute;
      bottom: 52rpx;
      left: 16rpx;
      padding-left: 8rpx;
      padding-right: 16rpx;
      border-radius: 40rpx;
    }
  }
  .title {
    max-height: 88rpx;
    overflow: hidden;
  }
  .label {
    display: flex;
    align-items: center;
    gap: 8rpx;

    flex-shrink: 0;
    margin-right: 8rpx;
  }
  .label-trade {
    color: #ff8c42;
  }
  .auctionPrice {
    display: flex;
    align-items: baseline;
  }
  .startingPrice {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.64);
  }
  .seller {
    display: flex;
    align-items: center;
    gap: 8rpx;
    .avatar {
      width: 32rpx;
      height: 32rpx;
      border-radius: 50%;
      object-fit: cover;
    }
    .nickname {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
